import React, { useState } from 'react'
import api from 'axios'
import { useForm } from 'react-hook-form'

import './register-form.scss'

import AsideContact from '../AsideContact'

const RegisterForm = () => {

    const a = Math.floor((Math.random() * 5) + 1)
    const b = Math.floor((Math.random() * 5) + 1)
    const count = `${a} + ${b}`
    const pass = a + b

    const [FormDefault, setFormDefault] = useState(true);
    const [FormSuccess, setFormSuccess] = useState(false);
    const [FormError, setFormError] = useState(false);
    const { handleSubmit, register, errors } = useForm()
    
    const onSubmit = values => {
        api({
            method: "POST", 
            url:"http://postman.yupper.digital/grupo-reinserir", 
            data: { values }
        }).then((response)=>{
            if (response.data.msg === 'success'){
                setFormDefault(false)
                setFormSuccess(true)
            } else if (response.data.msg === 'fail') {
                setFormDefault(false)
                setFormError(true)
            }
        })
    }

    return(
        <div className="contact-form">
            <div className="contact-form__container">
                <div className="contact-form__content">
                    { FormDefault &&
                        <form onSubmit={handleSubmit(onSubmit)} className="form">
                            <div className="form__row">
                                <label htmlFor="Nome">
                                    Seu nome
                                    <input // eslint-disable-line
                                        name="Nome"
                                        id="Nome"
                                        type="text"
                                        className="form__control"
                                        ref={register({
                                            required: "Obrigatório",
                                        })}
                                    />
                                </label>
                                {errors.full_name && 
                                    <p className="form__error">{ errors.full_name.message }</p> }
                            </div>

                            <div className="form__row">
                                <label htmlFor="email">
                                    Seu email
                                    <input // eslint-disable-line
                                        name="Email"
                                        className="form__control"
                                        ref={register({
                                            required: "Obrigatório",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Email inválido"
                                            }
                                        })}
                                    />
                                </label>
                                {errors.email && 
                                    <p className="form__error">{ errors.email.message }</p> }
                            </div>

                            <div className="form__row">
                                <label htmlFor="subject">
                                    Assunto da mensagem
                                    <input // eslint-disable-line
                                        name="Assunto"
                                        className="form__control"
                                        ref={register({
                                            required: "Obrigatório"
                                        })}
                                    />
                                </label>
                                {errors.subject && 
                                    <p className="form__error">{ errors.subject.message }</p> }
                            </div>

                            <div className="form__row">
                                <label htmlFor="message">
                                    Mensagem
                                    <textarea // eslint-disable-line
                                        name="Mensagem"
                                        id="Mensagem"
                                        className="form__control"
                                        rows="10"
                                        ref={register({
                                            required: "Obrigatório",
                                        })}
                                    />
                                </label>
                                {errors.message && 
                                    <p className="form__error">{ errors.message.message }</p> }
                            </div>

                        <div className="form__row form__row--md">
                                <label htmlFor="prevent">
                                    Quanto é  { count }?
                                    <span className="form__help">Para previnir SPAN</span>
                                    <input // eslint-disable-line
                                        name="prevent" 
                                        id="prevent"
                                        className="form__control"
                                        ref={register({
                                            required: "Obrigatório",
                                            validate: value => value === ''+ pass +'' || 'Código inválido'
                                        })}
                                    />
                                </label>
                                { errors.prevent && 
                                    <p className="form__error">{ errors.prevent.message }</p> }
                            </div>

                            <div className="form__button">
                                <button type="submit" aria-label="Send">Enviar mensagem</button>
                            </div>
                        </form>
                    }

                    { FormSuccess &&
                        <div className="form__success">
                            <h3>Mensagem <em>enviada</em> com sucesso!</h3>
                            <p>Em breve entraremos em contato.</p>
                            <p>Siga-nos no <a href="https://www.facebook.com/gruporeinserir" target="_blank" rel="noreferrer">Facebook</a> e <a href="https://www.instagram.com/gruporeinserir/" target="_blank" rel="noreferrer">Instagram</a></p>
                        </div>
                    }
                    
                    
                    { FormError &&
                        <div className="form__error">
                            <h3>Mensagem <em>não foi</em> enviada.</h3>
                            <p>Algum erro ocorreu ao enviar a mensagem. Tente novamente mais tarde.</p>
                            <p>Ou siga-nos no <a href="https://www.facebook.com/gruporeinserir" target="_blank" rel="noreferrer">Facebook</a> e <a href="https://www.instagram.com/gruporeinserir/" target="_blank" rel="noreferrer">Instagram</a></p>
                        </div>
                    }

                </div>

                <AsideContact />

            </div>
        </div>
    )
}

export default RegisterForm
